import { configureStore } from '@reduxjs/toolkit'
import userDetailsSlice from './slices/userDetailsSlice';
import projectDetailsSlice from './slices/projectDetailSlice';
import sortProjectDetailsSlice from './slices/sortProjectDetailsSlice';
import skuCodeAndNameSlice from './slices/skuCodeAndNameSlice';
import delistBomSlice from './slices/delistBomSlice';

const reducer = {
  userDetails: userDetailsSlice,
  projectDetails:projectDetailsSlice,
  sortProjectDetails:sortProjectDetailsSlice,
  skuCodeAndNames: skuCodeAndNameSlice,
  delistBom: delistBomSlice
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;